import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { map } from 'rxjs';
import { FormBuilderFacade } from '../../store/formBuilder.facade';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [NzMenuModule, RouterModule, NzIconModule, CommonModule, NzSkeletonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  constructor(private stateFacade: FormBuilderFacade) {}

  loading$ = this.stateFacade.formLoading$;
  formId$ = this.stateFacade.formInfo$.pipe(map((formInfo) => formInfo?.id));

  formType$ = this.stateFacade.formInfo$.pipe(
    map((formInfo) => formInfo?.formType)
  );
}
