<nz-layout>
    <nz-sider class="slider" nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
        <app-logo />
        <app-navbar />
    </nz-sider>
    <nz-layout>
        <nz-header>
            <span class="trigger" style="font-size: 20px;" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                (click)="isCollapsed = !isCollapsed"></span>

            <div class="header-right">
                <nz-badge [nzCount]="0">
                    <span nz-icon nzType="bell" nzTheme="outline" style="font-size: 20px;"></span>
                </nz-badge>
                <app-options-dropdown />
            </div>
        </nz-header>
        <nz-content>
            <div class="inner-content">
                <!-- <ng-content></ng-content> -->
    <router-outlet />

            </div>
        </nz-content>
    </nz-layout>
</nz-layout>
