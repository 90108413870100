import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { selectCurrentUser, selectIsLoggedIn, selectIsUserOnboarded } from '../store/auth.selectors';
import { AuthFacade } from '../store/auth.facade';
import { TokenStorageService } from '../../services/token-storage.service';

export const authGuard: CanActivateFn = (route, state) => {

  const store = inject(Store);
  const router = inject(Router);

  return combineLatest([
    store.select(selectCurrentUser),
    store.select(selectIsLoggedIn),
    store.select(selectIsUserOnboarded)
  ]).pipe(
    take(1),
    switchMap(([currentUser, isLoggedIn]) => {
      if (!isLoggedIn) {
        router.navigate(['/auth/signin']);
        return EMPTY;
      }
      return of(isLoggedIn);
    })
  );
};


