import { Component, inject } from '@angular/core';
import { AuthFacade } from '../../../auth/store/auth.facade';
import { TokenStorageService } from '../../../services/token-storage.service';
import { tap } from 'rxjs';
import { IUser } from '../../../auth/store/auth.models';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModule } from '@angular/common';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { Router } from '@angular/router';

@Component({
  selector: 'app-options-dropdown',
  standalone: true,
  imports: [NzIconModule, CommonModule, NzAvatarModule, NzDropDownModule],
  templateUrl: './options-dropdown.component.html',
  styleUrl: './options-dropdown.component.scss',
})
export class OptionsDropdownComponent {
  private readonly authFacade = inject(AuthFacade);
  private readonly tokenStorage = inject(TokenStorageService);
  private readonly router = inject(Router);

  user: IUser | null = null;
  initials: string = '';

  user$ = this.authFacade.currentUser$.pipe(
    tap((x) => {
      if (x) {
        this.user = x;
        this.initials =
          this.user?.firstName?.substring(0, 1) +
          this.user?.lastName?.substring(0, 1);
      } else {
        this.user = null;
      }
    })
  );

  logout() {
    this.authFacade.logout({
      refreshKey: this.tokenStorage.getRefreshToken(),
    });
  }

  profile(): void {
    this.router.navigate(['/accounts/myProfile']);
  }
}
