// auth.effects.ts

import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AuthService } from '../auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { Router } from '@angular/router';
import { selectAuthPassword, selectRegisteredEmail } from './auth.selectors';
import { AppSettingsService } from '../../services/app-settings.service';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private appSetting: AppSettingsService,
    private readonly store: Store = inject(Store)
  ) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginRequest),
      mergeMap((action) =>
        this.authService.login(action.credentials).pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if ("accessToken" in response) {
              this.tokenStorageService.saveTokens(response.accessToken, response.refreshKey, response.expiresIn * 100 + new Date().getTime());
              return AuthActions.loginSuccess()
            } else {
              if (statusCode === 200 && response?.isVerificationRequired) {
                this.router.navigateByUrl('/auth/verify');
                let payload = {
                  email: action?.credentials?.email,
                  password: action?.credentials?.password
                }
                return AuthActions.setAuthCredentials({ credentials: payload })
              }
              return AuthActions.loginFailure({ error: response });
            }
          }),
          catchError(error => of(AuthActions.loginFailure({ error: error })))
        )
      )
    );
  }, { dispatch: true });

  // Other effects...

  // onLoginSuccess$
  onLoginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      map(() => {
        return AuthActions.getAuthUserRequest();
      })
    );
  }, { dispatch: true });

  onLoginOrRefreshTokenFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.loginFailure, AuthActions.refreshTokenFailure),
        tap(() => {
          this.tokenStorageService.removeTokens();
        }),
        // map(() => AuthActions.logout({ credentials: { refreshKey: this.tokenStorageService.getRefreshToken() } }))
      );
    },
    { dispatch: false }
  );

  signup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signupRequest),
      mergeMap(action =>
        this.authService.signup(action.credentials).pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              this.router.navigateByUrl('/auth/verify');
              let payload = {
                email: action?.credentials?.email,
                password: action?.credentials?.password
              }
              return AuthActions.setAuthCredentials({ credentials: payload });
            }
            return AuthActions.signupFailure({ error: response });
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.signupFailure({ error: error }))
          })
        )
      )
    )
  })

  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmailRequest),
      mergeMap(action =>
        this.authService.verifyEmail(action.credentials).pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              // this.router.navigateByUrl('/onboarding');
              return AuthActions.verifyEmailSuccess();
            }
            return AuthActions.verifyEmailFailure({ error: response });
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.verifyEmailFailure({ error: error }))
          })
        )
      )
    )
  })

  verifyEmailSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyEmailSuccess),
      withLatestFrom(
        this.store.select(selectRegisteredEmail),
        this.store.select(selectAuthPassword)
      ),
      mergeMap(([action, email, password]) => {
        let payload = {
          applicationId: this.appSetting.AppId,
          email: email,
          password
        }
        return of(AuthActions.loginRequest({ credentials: payload }))
      })
    )
  })

  resendVerifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resendVerifyEmailRequest),
      mergeMap(action =>
        this.authService.resendVerifyEmail(action.credentials).pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              return AuthActions.resendVerifyEmailSuccess();
            }
            return AuthActions.resendVerifyEmailFailure({ error: response });
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.resendVerifyEmailFailure({ error: error }))
          })
        )
      )
    )
  })

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.logout),
        exhaustMap((action) =>
          this.authService.logout(action.credentials).pipe(
            map(response => {
              return AuthActions.logoutSuccess();
            }),
            catchError(() => of(AuthActions.logoutSuccess()))
          )
        )
      );
    },
    { dispatch: true }
  );

  logoutSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logoutSuccess),
      tap(() => {
        this.tokenStorageService.removeTokens()
        this.router.navigateByUrl('/auth/signin');
      })
    );
  }, { dispatch: false })

  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthUserRequest),
      exhaustMap(() =>
        this.authService.getMe().pipe(
          map(response => {
            if ("userProfile" in response && response?.userProfile) {
              return AuthActions.getAuthUserSuccess({ user: response.userProfile, userPrimaryAddress: response.userPrimaryAddress });
            } else {
              return AuthActions.getAuthUserFailure();
            }
          }),
          catchError(() => of(AuthActions.getAuthUserFailure()))
        )
      )
    );
  }, { dispatch: true });

  onGetAuthUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthUserSuccess),
      tap((action) => {
        const currentUrl = this.router.url;
        if (!action.user.isOnboarded) {
          if (action.user.isEmailVerified && !action.user.isPhoneNoVerified) {
            return this.router.navigateByUrl('/onboarding');
          } else if (action.user.isEmailVerified && action.user.isPhoneNoVerified) {
            return this.store.dispatch(AuthActions.markasOnboardRequest())
          }
          return this.router.navigateByUrl('/onboarding');
        }


        if (action.user.isOnboarded) {
          if(currentUrl === '/auth/signin' || currentUrl === '/auth/signup') {
          return this.router.navigateByUrl('/forms/myforms');
          }
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  onGetAuthUserFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.getAuthUserFailure),
      exhaustMap(() => {
        return of(AuthActions.logoutSuccess());
      }),
    );
  }, { dispatch: true })

  onGetTokensFromStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadTokenFromStorage),
      exhaustMap(() => {
        const accessToken = this.tokenStorageService.getAccessToken();
        const refreshToken = this.tokenStorageService.getRefreshToken();
        if (accessToken && refreshToken) {

          // TODO: check if token is expired and refresh if needed

          return of(AuthActions.loadTokenSuccess({
            accessToken: accessToken,
            refreshKey: refreshToken
          }));
        } else {
          return of(AuthActions.loadTokenFailure());
        }
      })
    );
  }, { dispatch: true });

  onLoadTokenSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadTokenSuccess),
      map(() => {
        return AuthActions.getAuthUserRequest();
      })
    );
  }, { dispatch: true })

  onLoadTokenFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadTokenFailure),
      tap(() => {
        this.tokenStorageService.removeTokens();
      })
    );
  }, { dispatch: false });

  onUpdateProfileRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateProfileRequest),
      mergeMap(action =>
        this.authService.updateMe(action.credentials).pipe(
          concatMap(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              if ("phoneVerificationId" in response && "phonenoVerificationRequired" in response && (response?.phonenoVerificationRequired)) {
                this.authService.setProfileasUpdated(true);
                const verificationAction = AuthActions.setMobileVerificationId({ verificationId: response?.phoneVerificationId })
                const profileUpdateSucessAction = AuthActions.updateProfileSuccess()
                return of(verificationAction, profileUpdateSucessAction);
              } else {
                return of(AuthActions.updateProfileSuccess())
              }
            }
            return of(AuthActions.updateProfileFailure({ error: response }));
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.updateProfileFailure({ error: error }))
          })
        )
      )
    )
  })

  onUpdateProfileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateProfileSuccess),
      tap(() => {
        this.store.dispatch(AuthActions.getAuthUserRequest());
      }),
    )
  }, { dispatch: false })

  onLoadCountries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadCountryRequest),
      switchMap(() =>
        this.authService.loadCountries().pipe(
          map(response => {
            if ('data' in response && response?.data) {
              const { headers: { statusCode }, data } = response;
              if (statusCode == 200) {
                return AuthActions.loadCountrySuccess({ data });
              } else {
                return AuthActions.loadCountryFailure()
              }
            } else {
              return AuthActions.loadCountryFailure();
            };
          }),
          catchError(() => of(AuthActions.loadCountryFailure()))
        )
      )
    );
  }, { dispatch: true });

  onLoadTimezone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.loadTimeZoneRequest),
      switchMap(() =>
        this.authService.loadTimezones().pipe(
          map(response => {
            if ('data' in response && response?.data) {
              const { headers: { statusCode }, data } = response;
              if (statusCode == 200) {
                return AuthActions.loadTimeZoneSuccess({ data });
              } else {
                return AuthActions.loadTimeZoneFailure()
              }
            } else {
              return AuthActions.loadTimeZoneFailure();
            };
          }),
          catchError(() => of(AuthActions.loadTimeZoneFailure()))
        )
      )
    );
  }, { dispatch: true });

  verifyPhoneNo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyPhoneNoRequest),
      mergeMap(action =>
        this.authService.verifyPhoneNo(action.credentials).pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              return AuthActions.verifyPhoneNoSuccess();
            }
            return AuthActions.verifyPhoneNoFailure({ error: response });
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.verifyPhoneNoFailure({ error: error }))
          })
        )
      )
    )
  })

  resendPhoneVerification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resendOTPRequest),
      mergeMap(() =>
        this.authService.resendPhoneVerification().pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            console.log(response);
            if (statusCode === 200 && "phoneVerificationId" in response) {
              return AuthActions.resendOTPSuccess({ verificationId: response.phoneVerificationId });
            }
            return AuthActions.resendOTPFailure({ error: response });
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.resendOTPFailure({ error: error }))
          })
        )
      )
    )
  })

  onVerifyPhoneNoSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.verifyPhoneNoSuccess),
      tap(() => {
        this.store.dispatch(AuthActions.getAuthUserRequest());
      }),
    )
  }, { dispatch: false })

  onMarkOnboardedRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.markasOnboardRequest),
      mergeMap((action) => {
        return this.authService.markAsOnboarded().pipe(
          map(response => {
            const { headers: { statusCode } } = response;
            if (statusCode === 200) {
              return AuthActions.markasOnboardSuccess();
            }
            return AuthActions.markasOnboardFailure();
          }),
          catchError(error => {
            console.log("error: ", error);
            return of(AuthActions.markasOnboardFailure());
          })
        );
      })
    )
  }, { dispatch: true })

  markOnBoardedSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.markasOnboardSuccess),
      tap(() => this.router.navigateByUrl('/onboarding/onboard'))
      // map(() => AuthActions.getAuthUserRequest()),
    );
  }, { dispatch: false })
}
