import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { LogoComponent } from '../logo/logo.component';
import { UserProfileSliderComponent } from '../user-profile-slider/user-profile-slider.component';
import { NavbarComponent } from '../../../form-builder/components/navbar/navbar.component';
import { OptionsDropdownComponent } from '../options-dropdown/options-dropdown.component';
import { Subject } from 'rxjs';
import { FormBuilderFacade } from '../../../form-builder/store/formBuilder.facade';
import { IResponsesRequest } from '../../../models/qr';

@Component({
  selector: 'app-builder-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NzLayoutModule,
    NzIconModule,
    NzBadgeModule,
    LogoComponent,
    NavbarComponent,
    OptionsDropdownComponent,
    RouterModule,
  ],
  templateUrl: './builder-layout.component.html',
  styleUrl: './builder-layout.component.scss',
})
export class BuilderLayoutComponent {
  isCollapsed = false;

  route = inject(ActivatedRoute);
  private readonly FormBuilderFacade = inject(FormBuilderFacade);
  private unsubscribe$ = new Subject<void>();

  formId: string = '';

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.formId = params['id'];
      const responseParams: IResponsesRequest = {
        conditions: [],
        formId: this.formId,
        limit: 0,
        pageNo: 0,
        searchString: '',
        sortOrder: 'DESC',
      };
      this.FormBuilderFacade.fetchFormInfo(this.formId);
      // this.FormBuilderFacade.fetchAllResponses(responseParams);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
