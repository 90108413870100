import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormService } from '../services/form.service';
import * as FormResponseActions from './formBuilder.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';

@Injectable()
export class FormResponseEffects {
  constructor(
    private actions$: Actions,
    private formService: FormService,
    private messageService: NzMessageService,
    private router: Router
  ) {}

  loadFormInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.loadFormInfo),
      switchMap((action) =>
        this.formService.getForm(action.id).pipe(
          map((response) => {
            if ('recordInfo' in response) {
              return FormResponseActions.loadFormInfoSuccess(response);
            } else {
              return FormResponseActions.loadFormInfoFailed(response);
            }
          }),
          catchError((error) =>
            of(FormResponseActions.loadFormInfoFailed(error.error))
          )
        )
      )
    );
  });

  loadFormResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.loadFormResponse),
      switchMap((action) =>
        this.formService.getResponses(action.formRequest).pipe(
          map((response) => {
            if ('data' in response) {
              return FormResponseActions.loadFormResponseSuccess(response);
            } else {
              return FormResponseActions.loadFormResponseFailed(response);
            }
          }),
          catchError((error) =>
            of(FormResponseActions.loadFormResponseFailed(error.error))
          )
        )
      )
    );
  });

  addFormResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.addFormResponse),
      switchMap((action) =>
        this.formService.addResponse(action.formData, action.formId).pipe(
          map((response) => {
            if (response.headers.statusCode === 200) {
              this.messageService.success('Added QR successfully', {
                nzDuration: 2000,
                nzAnimate: true,
              });
              return FormResponseActions.addFormResponseSuccess(response);
            } else {
              return FormResponseActions.addFormResponseFailed(response);
            }
          }),
          catchError((error) =>
            of(FormResponseActions.addFormResponseFailed(error.error))
          )
        )
      )
    );
  });

  addFormResponseSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.addFormResponseSuccess),
      switchMap((action) => {
        // redirect to form response page
        this.router.navigate(['/form', action.recordInfo.formId]);
        return of(
          FormResponseActions.loadFormResponse({
            formRequest: {
              formId: action.recordInfo.formId,
              conditions: [],
              limit: 0,
              pageNo: 0,
              searchString: '',
              sortOrder: 'DESC',
            },
          })
        );
      })
    );
  });

  updateFormResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.updateFormResponse),
      switchMap((action) =>
        this.formService
          .updateResponse(action.formData, action.formId, action.responseId)
          .pipe(
            map((response) => {
              if (response.headers.statusCode === 200) {
                this.messageService.success('Updated successfully', {
                  nzDuration: 2000,
                  nzAnimate: true,
                });
                return FormResponseActions.updateFormResponseSuccess({
                  formId: action.formId,
                });
              } else {
                return FormResponseActions.updateFormResponseFailed(response);
              }
            }),
            catchError((error) =>
              of(FormResponseActions.updateFormResponseFailed(error.error))
            )
          )
      )
    );
  });

  updateFormResponseSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.updateFormResponseSuccess, FormResponseActions.generateQRSuccess),
      switchMap((action) => {
        return of(
          FormResponseActions.loadFormResponse({
            formRequest: {
              formId: action.formId,
              conditions: [],
              limit: 0,
              pageNo: 0,
              searchString: '',
              sortOrder: 'DESC',
            },
          })
        );
      })
    );
  });

  //Error for add and update response
  addUpdateResponseError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          FormResponseActions.addFormResponseFailed,
          FormResponseActions.updateFormResponseFailed,
          FormResponseActions.deleteResponseFailed
        ),
        map((action) => {
          this.messageService.error(action.headers.message, {
            nzDuration: 2000,
            nzAnimate: true,
          });
        })
      );
    },
    { dispatch: false }
  );

  generateQRCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.generateQR),
      switchMap((action) =>
        this.formService
          .generateQR({ formId: action.formId, responseId: action.responseId })
          .pipe(
            map((response) => {
              if (response.headers.statusCode === 200) {
                return FormResponseActions.generateQRSuccess({
                  formId: action.formId,
                });
              } else {
                return FormResponseActions.generateQRFailed(response);
              }
            }),
            catchError((error) =>
              of(FormResponseActions.loadFormInfoFailed(error.error))
            )
          )
      )
    );
  });

  updateDisplayConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.updateDisplayConfig),
      switchMap((action) =>
        this.formService
          .updateDisplayConfig(action.formId, action.displayConfig)
          .pipe(
            map((response) => {
              if (response.headers.statusCode === 200) {
                this.messageService.success('Updated successfully', {
                  nzDuration: 2000,
                  nzAnimate: true,
                });
                return FormResponseActions.updateDisplayConfigSuccess({formId: action.formId});
              } else {
                return FormResponseActions.updateDisplayConfigFailed(response);
              }
            }),
            catchError((error) =>
              of(FormResponseActions.updateDisplayConfigFailed(error.error))
            )
          )
      )
    );
  })

  updateDisplayConfigSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.updateDisplayConfigSuccess),
      switchMap((action) => {
        return of(
          FormResponseActions.loadFormInfo({
            id: action.formId
          })
        );
      })
    );
  });

  deleteResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.deleteResponse),
      switchMap((action) =>
        this.formService
          .deleteResponse({ formId: action.formId, responseId: action.responseId })
          .pipe(
            map((response) => {
              if (response.headers.statusCode === 200) {
                this.messageService.success('Deleted successfully', {
                  nzDuration: 2000,
                  nzAnimate: true,
                });
                return FormResponseActions.deleteResponseSuccess({formId: action.formId, responseId: action.responseId});
              } else {
                return FormResponseActions.deleteResponseFailed(response);
              }
            }),
            catchError((error) =>
              of(FormResponseActions.deleteResponseFailed(error.error))
            )
          )
      )
    );
  });

  deleteResponseSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FormResponseActions.deleteResponseSuccess),
      switchMap((action) => {
        return of(
          FormResponseActions.loadFormResponse({
            formRequest: {
              formId: action.formId,
              conditions: [],
              limit: 0,
              pageNo: 0,
              searchString: '',
              sortOrder: 'DESC',
            },
          })
        );
      })
    );
  });


}
