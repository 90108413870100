import { IAPIResponse, IAPIResponseHeaders } from "../../models/base";
import { ICondition } from "./qr";

export enum FormTypes {
    GENERIC = "generic",
    QRCODE = "qrcode"
}

export const FormTypesOptions: { [key: string]: string } = {
    generic: "Generic",
    qrcode: "QR Code"
}

export interface IForm {
    id: string;
    accountId: string;
    description: string;
    formName: string;
    formType: FormTypes;
    formFields: IFormField[];
    emailAlertsConfig: IEmailAlertsConfig;
    displayConfig?: IDisplayConfig;
    formConfig: IFormConfig;
    createdAt: string;
    updatedAt: string;
    createdBy: string;
    modifiedBy: string;
    isPublic: boolean;
}

export interface IDisplayConfig {
    backgroundColor: string;
    textColor: string;
    primaryColor: string;
    labelColor: string;
    primaryFont: string;
    displayFont: string;
    formHeading: string;
    formSubHeading: string;
    submitButtonLabel: string;
}

export interface IFormField {
    fieldId: string;
    fieldType: FieldType;
    dataType: DataType;
    fieldLabel: string;
    placeHolder: string;
    toolTip: string;
    isDisabled: boolean;
    options: null | string[];
    validations: IValidation[];
    createdAt: Date;
    updatedAt: Date;
    position: number;
}

export interface IFormFieldRequest {
    formId: string;
    fieldType: FieldType;
    label: string;
    placeHolder: string;
    toolTip: string;
    isDisabled: boolean;
    options: null | string[];
    validations: IValidation[];
}

export interface IGenericFormInfo {
    id: string;
    formType: FormTypes;
    formName: string;
    description: string | null;
    customCSSLink: string | null;
    loginRequired: boolean;
    successMessage: string | null;
    displayConfig: IDisplayConfig;
}

export interface IDisplayConfig {
    backgroundColor: string;
    textColor: string;
    primaryColor: string;
    labelColor: string;
    primaryFont: string;
    displayFont: string;
    formHeading: string;
    formSubHeading: string;
    submitButtonLabel: string;
}

export enum DataType {
    String = "STRING",
}

export enum FieldType {
    Image = "image",
    Text = "text",
    Textarea = "textarea",
    Select = "select",
    MultiSelect = "multiselect",
    Section = "section",
    Number = "number",
    Radio = "radio",
    Calendar = "calendar"
}
export interface IValidation {
    rule: ValidationRules;
    value: string;
}

export enum ValidationRules {
    REQUIRED = "required",
    MIN_LENGTH = "minlength",
    MAX_LENGTH = "maxlength",
    EMAIL = "email",
    URL = "url",
    MIN_VALUE = "minvalue",
    MAX_VALUE = "maxvalue",
    REGEX = "regex",
    ALPHANUMERIC = "alphanumeric",
    NUMBER = "number",
    ALLOWNEGATIVE = "allownegative",
    ALLOWDECIMALS = "allowdecimals",
    DECIMALPOINTS = "noofdecimalpoints",
    ALLOW_MULTIPLE = "allowmultiple",
    ALLOW_PAST = "allowpast",
    ALLOW_FUTURE = "allowfuture",
    DATE_FORMAT = "dateformat"
}

export interface IEmailAlertsConfig {
    enabled: boolean;
    toEmails: string;
    fromEmail: string;
    bccEmails: string;
    templateContent: string;
}

export interface IFormConfig {
    dataSyncOptions: IDataSyncOptions;
    customCSSLink: string;
    loginRequired: boolean;
    successMessage: string;
    isFieldCustomizable: boolean;
}

export interface IDataSyncOptions {
    enabled: boolean;
    hookUrl: string;
}


export interface IFormResponse extends IAPIResponse<IForm> {
    recordInfo: NonNullable<IForm>;
}

export interface IPublicGenericFormResponse {
    headers: IAPIResponseHeaders,
    formInfo: IGenericFormInfo,
    formFields: IFormField[]
}

export interface IFieldTypesResponse {
    headers: IAPIResponseHeaders,
    formFields: IFieldTypes
}

export interface IDateFormatResponse {
    headers: IAPIResponseHeaders,
    data: string[]
}

export type IFieldTypes = {
    [key in FieldType]: ValidationRules[];
};

export interface IFieldOrderRequest {
    fieldId: string;
    formId: string;
    newPosition: number;
}

export interface IFieldDeleteRequest {
    fieldId: string;
    formId: string;
}

export interface IFieldUpdateRequest {
    fieldId: string,
    formId: string,
    fieldType: string,
    placeHolder: string,
    label: string,
    toolTip: string,
    disabled: boolean,
    options: string[],
    validationRules:
    {
        rule: string,
        value: string
    }[]

}

export interface IResponsesRequest {
    conditions: ICondition[];
    formId: string;
    limit: number;
    pageNo: number;
    searchString: string;
    sortOrder: string;
}

export interface IFilterMasterResponse {
    headers: IAPIResponseHeaders,
    data: IFilterResponse[]
}

export interface IFilterResponse {
    dataType: string;
    conditions: IFilterCondition[];
}

export interface IFilterCondition {
    key: string;
    label: string;
}
