<ul nz-menu nzMode="inline">
    <!-- <li nz-menu-item nzMatchRouter routerLink="dashboard">
        <span nz-icon nzType="dashboard"></span>
        <span>Dashboard</span>
    </li> -->
    <!-- <li nz-submenu nzTitle="Form" nzIcon="form"> -->
        <ul>
            <li nz-menu-item routerLink="/forms/myforms" nzMatchRouter>My Forms</li>
            <li nz-menu-item routerLink="/forms/trash" nzMatchRouter>Trash</li>
        </ul>
    <!-- </li> -->
    <!-- <li nz-submenu nzTitle="Account" nzIcon="user"> -->
        <ul>
            <li nz-menu-item routerLink="/accounts/myProfile" nzMatchRouter>Profile</li>
            <!-- <li nz-menu-item nzMatchRouter>Billing</li>
            <li nz-menu-item nzMatchRouter>Payment Info</li> -->
        </ul>
    <!-- </li> -->
</ul>
