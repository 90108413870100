import { createFeature, createReducer, on } from "@ngrx/store";
import { formResponseAdapter, initialFormResponseState } from "./formBuilder.state";
import * as FormResponseActions from './formBuilder.actions';
import { state } from "@angular/animations";
import { Action } from "rxjs/internal/scheduler/Action";

export const formResponseReducer = createReducer(
  initialFormResponseState,

  on(FormResponseActions.loadFormInfo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.loadFormInfoSuccess, (state, { recordInfo }) => ({
    ...state,
    isLoading: false,
    formInfo: recordInfo,
  })),
  on(FormResponseActions.loadFormInfoFailed, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(FormResponseActions.loadFormResponse, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.loadFormResponseSuccess, (state, { data }) => {
    return formResponseAdapter.setAll(data, { ...state, isLoading: false });
  }),
  //add Form Response
  on(FormResponseActions.addFormResponse, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.addFormResponseFailed, (state, { errorlist }) => ({
    ...state,
    isLoading: false,
    errorList: errorlist || {},
  })),
  on(FormResponseActions.addFormResponseSuccess, (state, { recordInfo }) => {
    return formResponseAdapter.addOne(recordInfo, {
      ...state,
      isLoading: false,
    });
  }),
  //update Form Response
  on(FormResponseActions.updateFormResponse, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.updateFormResponseFailed, (state, { errorlist }) => ({
    ...state,
    isLoading: false,
    errorList: errorlist || {},
  })),
  on(FormResponseActions.updateFormResponseSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  //generate QR,
  on(FormResponseActions.generateQR, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.generateQRFailed, (state, { errorlist }) => ({
    ...state,
    isLoading: false,
    errorList: errorlist || {},
  })),
  on(FormResponseActions.generateQRSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),

  // clear error list
  on(FormResponseActions.clearFormErrorList, (state) => ({
    ...state, errorList: {}}),),

    // update display config

  on(FormResponseActions.updateDisplayConfig, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.updateDisplayConfigFailed, (state, { errorlist }) => ({
    ...state,
    isLoading: false,
    errorList: errorlist || {},
  })),
  on(FormResponseActions.updateDisplayConfigSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),

  // delete response

  on(FormResponseActions.deleteResponse, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormResponseActions.deleteResponseFailed, (state, { errorlist }) => ({
    ...state,
    isLoading: false,
    errorList: errorlist || {},
  })),
  on(FormResponseActions.deleteResponseSuccess, (state, {formId, responseId}) => {
    return formResponseAdapter.removeOne(responseId, {...state, isLoading: false});
  }
  )


);

export const formResponseFeature = createFeature({
    name: 'formResponseState',
    reducer: formResponseReducer
})
