import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthFacade } from './auth/store/auth.facade';
import { LayoutComponent } from './ui-components/layout/layout.component';
import { S3Service } from './services/s3.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'nexus-console';
  
  constructor(private authFacade: AuthFacade) {}
  s3Service = inject(S3Service);


  isOnboarded$ = this.authFacade.isOnboarded$;

  ngOnInit(): void {
    this.authFacade.loadTokenFromStorage();
    this.s3Service.initializeClient();

  }
}
