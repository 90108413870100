import { Component } from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzBadgeModule } from 'ng-zorro-antd/badge';

import { LogoComponent } from './logo/logo.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UserProfileSliderComponent } from './user-profile-slider/user-profile-slider.component';
import { RouterOutlet } from '@angular/router';
import { OptionsDropdownComponent } from "./options-dropdown/options-dropdown.component";



@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [RouterOutlet, NzLayoutModule, NzIconModule, NzBadgeModule, LogoComponent, NavbarComponent, OptionsDropdownComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {

  isCollapsed = false;
}
