<nz-skeleton [nzActive]="true" [nzLoading]="(loading$ | async) || false">
  <ul nz-menu nzMode="inline" *ngIf="formId$ | async as formId">
    <!-- <li  nz-submenu nzTitle="Form" nzIcon="form">
        <ul>

        </ul>
    </li> -->
    <li
      *ngIf="(formType$ | async) !== 'qrcode'"
      nz-menu-item
      nzMatchRouter
      [nzMatchRouterExact]="true"
      [routerLink]="['/form', formId, 'builder']"
    >
      <span nz-icon nzType="build"></span>
      <span>Builder</span>
    </li>
    <li
      *ngIf="(formType$ | async) !== 'qrcode'"
      nz-menu-item
      nzMatchRouter
      [nzMatchRouterExact]="true"
      [routerLink]="['/form', formId, 'responses']"
    >
      <span nz-icon nzType="unordered-list" nzTheme="outline"></span>
      <span>Responses</span>
    </li>

    <li
      *ngIf="(formType$ | async) === 'qrcode'"
      nz-menu-item
      nzMatchRouter
      [nzMatchRouterExact]="true"
      [routerLink]="['/form', formId]"
    >
      <span nz-icon nzType="qrcode" nzTheme="outline"></span>
      <span>QR Codes</span>
    </li>
    <li
      nz-menu-item
      nzMatchRouter
      [nzMatchRouterExact]="true"
      [routerLink]="['/form', formId, 'trash']"
    >
      <span nz-icon nzType="rest" nzTheme="outline"></span>
      <span>Trash</span>
    </li>
  </ul>
</nz-skeleton>
