import { Routes, withComponentInputBinding } from '@angular/router';
import { PageNotFoundComponent } from './common-pages/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './common-pages/privacy-policy/privacy-policy.component';
import { CustomerAgreementComponent } from './common-pages/customer-agreement/customer-agreement.component';
import { noAuthGuard } from './auth/guard/no-auth.guard';
import { authGuard } from './auth/guard/auth.guard';
import { provideState } from '@ngrx/store';
import { formFeature } from './forms/my-forms/store/form.reducers';
import { provideEffects } from '@ngrx/effects';
import { FormEffects } from './forms/my-forms/store/form.effects';
import { LayoutComponent } from './ui-components/layout/layout.component';
import { BuilderLayoutComponent } from './ui-components/layout/builder-layout/builder-layout.component';
import { formResponseFeature } from './form-builder/store/formBuilder.reducers';
import { FormResponseEffects } from './form-builder/store/formBuilder.effects';
import { qrDataResolver } from './form-builder/resolvers/qr-data.resolver';
import { genericPublicFormsResolver } from './form-builder/resolvers/generic-public-forms.resolver';
import { PublicFormComponent } from './form-builder/form/public-form/public-form.component';
import { PublicQrComponent } from './form-builder/form/public-qr/public-qr.component';

export const routes: Routes = [
  { path: '', redirectTo: 'forms', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/routes').then((mod) => mod.AuthRoutes),
    canActivate: [noAuthGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./auth/routes').then((mod) => mod.OnboardingRoutes),
    canActivate: [authGuard],
  },
  {
    path: 'forms',
    component: LayoutComponent,
    loadChildren: () => import('./forms/routes').then((mod) => mod.FormsRoutes),
    canActivate: [authGuard],
    providers: [provideState(formFeature), provideEffects([FormEffects])],
  },
  {
    path: 'form/:id',
    component: BuilderLayoutComponent,
    loadChildren: () =>
      import('./form-builder/routes').then((mod) => mod.FormRoutes),
    canActivate: [authGuard],
    providers: [
      provideState(formResponseFeature),
      provideEffects([FormResponseEffects]),
    ],
  },
  {
    path: 'public/qr/:formId/:responseId',
    component: PublicQrComponent,
    resolve: {
      qrData: qrDataResolver,
    },
  },
  {
    path: 'public/generic/:formId',
    component: PublicFormComponent,
    resolve: {
      formData: genericPublicFormsResolver,
    },
  },
  {
    path: 'accounts',
    component: LayoutComponent,
    loadChildren: () =>
      import('./accounts/routes').then((mod) => mod.AccountRoutes),
    canActivate: [authGuard],
  },
  // { path: ':id/generic' },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'customer-agreement', component: CustomerAgreementComponent },
  // { path: '404', component: PageNotFoundComponent },
  // { path: '**', redirectTo: '404' },
];
