<a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" *ngIf="user$ | async as userOpts">
    @if (initials.length) {
    <nz-avatar [nzText]="initials" style="color:#ffffff; background-color:#1999e9;" [nzSize]="24"></nz-avatar>
    }
    @else {
    <nz-avatar nzIcon="user" [nzSize]="24"></nz-avatar>
    }
    {{userOpts?.firstName ?? "Hello User"}}
  </a>

  <nz-dropdown-menu #menu="nzDropdownMenu" class="dropdown">
      <ul nz-menu class="options">
        <li nz-menu-item (click)="profile()"><span nz-icon nzType="user" nzTheme="outline"></span> Profile</li>
        <li nz-menu-item (click)="logout()"><span nz-icon nzType="logout" nzTheme="outline"></span> Logout</li>
      </ul>
    </nz-dropdown-menu>
