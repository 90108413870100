import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { userAdapter, AuthState, initialAuthState } from './auth.state';
import { TokenStatus } from './auth.models';



// Create the reducer for the authentication state using the entity adapter
export const authReducers = createReducer(
    initialAuthState,

    on(
        AuthActions.signupRequest,
        (state): AuthState => ({
            ...state,
            isLoading: true,
            errorList: {},
            error: null
        })
    ),
    on(
        AuthActions.signupSuccess,
        (state): AuthState => ({
            ...state,
            isLoading: false,
            error: null,
            errorList: {}
        })
    ),

    on(
        AuthActions.signupFailure,
        (state, action): AuthState => ({
            ...state,
            isLoading: false,
            errorList: action?.error?.errorlist ?? {},
            error: action.error.headers ? action.error.headers.message : null,
        })
    ),
    
    on(
        AuthActions.loginRequest,
        (state) => ({
            ...state,
            accessTokenStatus: TokenStatus.VALIDATING,
            isLoadingLogin: true,
            hasLoginError: false,
            error: null,
            errorList: {}
        })
    ),

    // Refresh token
    on(
        AuthActions.refreshTokenRequest,
        (state): AuthState => ({
            ...state,
            refreshTokenStatus: TokenStatus.VALIDATING,
        })
    ),

    // Login & Refresh token
    on(
        AuthActions.loginSuccess,
        AuthActions.refreshTokenSuccess,
        (state): AuthState => ({
            ...state,
            isLoggedIn: true,
            isLoadingLogin: false,
            accessTokenStatus: TokenStatus.VALID,
            refreshTokenStatus: TokenStatus.VALID,
            error: null,
            hasLoginError: false,
            errorList: {}
        })
    ),
    on(
        AuthActions.loginFailure,
        AuthActions.refreshTokenFailure,
        (state, action): AuthState => {
            return {
                ...state,
                isLoadingLogin: false,
                accessTokenStatus: TokenStatus.INVALID,
                refreshTokenStatus: TokenStatus.INVALID,
                hasLoginError: action.type === '[Auth] Login Failure' && !!action.error,
                error: action.error.headers ? action.error.headers.message : null,
                errorList: action.error.errorlist ?? {}
            }
        }
    ),

    on(
        AuthActions.loadTokenSuccess,
        (state, action): AuthState => ({
            ...state,
            isLoggedIn: true,
            accessTokenStatus: TokenStatus.VALID,
            refreshTokenStatus: TokenStatus.VALID,
        })
    ),
    // create reducers for OTP success/fail and for forgot password

    on(AuthActions.logoutSuccess, AuthActions.loadTokenFailure, () => initialAuthState),

    on(
        AuthActions.verifyEmailRequest,
        (state): AuthState => ({
            ...state,
            isLoading: true,
            errorList: {}
        })
    ),
    on(
        AuthActions.verifyEmailSuccess,
        (state): AuthState => ({
            ...state,
            isLoading: false,
            errorList: {}
        })
    ),

    on(
        AuthActions.verifyEmailFailure,
        (state, action): AuthState => ({
            ...state,
            isLoading: false,
            errorList: action?.error?.errorlist ?? {},
            error: action.error.headers ? action.error.headers.message : null,
        })
    ),

    on(
        AuthActions.resendVerifyEmailFailure,
        (state, action): AuthState => ({
            ...state,
            errorList: action?.error?.errorlist ?? {},
            error: action.error.headers ? action.error.headers.message : null,
        })
    ),

    on(
        AuthActions.updateProfileRequest,
        (state): AuthState => ({
            ...state,
            isLoading: true,
            errorList: {}
        })
    ),

    on(
        AuthActions.updateProfileSuccess,
        (state): AuthState => ({
            ...state,
            isLoading: false,
            errorList: {}
        })
    ),

    on(
        AuthActions.updateProfileFailure,
        (state, action): AuthState => ({
            ...state,
            isLoading: false,
            errorList: action?.error?.errorlist ?? {}
        })
    ),

    on(AuthActions.getAuthUserSuccess, (state, { user, userPrimaryAddress }) => {
        return userAdapter.upsertOne(user, { ...state, primaryAddress: userPrimaryAddress });
    }),

    on(
        AuthActions.getAuthUserFailure,
        () => initialAuthState),
    
    on(
        AuthActions.setAuthCredentials,
        (state, action): AuthState => ({
            ...state,
            email: action?.credentials?.email,
            password: action?.credentials?.password,
            isLoadingLogin: false,
            isLoading: false
        })
    ),
    
    on(
        AuthActions.setMobileVerificationId,
        AuthActions.resendOTPSuccess,
        (state, action): AuthState => {
            return ({
            ...state,
            verificationId: action?.verificationId
        })
        
    }
    ),
    
    on(
        AuthActions.verifyPhoneNoRequest,
        (state): AuthState => ({
            ...state,
            isLoading: true
        })
    ),

    on(
        AuthActions.verifyPhoneNoSuccess,
        (state): AuthState => ({
            ...state,
            verificationId: '',
            isLoading: false,
            error: '',
            errorList: {},
        })
    ),

    on(
        AuthActions.verifyPhoneNoFailure,
        (state, action): AuthState => ({
            ...state,
            error: action?.error?.headers?.message,
            isLoading: false
        })
    ),

    on(
        AuthActions.resendOTPFailure,
        (state, action): AuthState => ({
            ...state,
            errorList: action?.error?.errorlist ?? {},
            error: action.error.headers ? action.error.headers.message : null,
        })
    ),

    on(
        AuthActions.loadCountrySuccess,
        (state, action): AuthState => {
            return{
                ...state,
                countryInfo: action.data
            }
        }
    ),
    
    on(
        AuthActions.loadTimeZoneSuccess,
        (state, action): AuthState => {
            return{
                ...state,
                timeZone: action.data
            }
        }
    ),

    on(
        AuthActions.onboardUserRequest, (state) => {
            const UserId = state.ids.length > 0 ? state.entities[state.ids[0]]?.id : null
            if (UserId) {
                return userAdapter.updateOne(
                    {
                        id: UserId,
                        changes:{
                            isOnboarded: true
                        }
                    },state
                )
            }
            return {...state}
        }
    )
);